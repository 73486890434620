import { GetServerSideProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useEffect, useState } from 'react';

import { postAPI } from '@apiClient/post-api';
import ContactUsForm from '@components/ContactUsForm';
import ModalVideo from '@components/ModalVideo';
import { OurAchievementsV2 } from '@components/_v2/pages/home/Achievements';
import Insight from '@components/_v2/pages/home/Insights';
import OrganizationsV2 from '@components/_v2/pages/home/Organizations';
import OurClientsSay from '@components/_v2/pages/home/OurClientsSay';
import { OurPortfolioV2 } from '@components/_v2/pages/home/OurPortfolio';
import OurServicesV2 from '@components/_v2/pages/home/OurServices';
import TechInsights from '@components/_v2/pages/home/TechInsights';
import OurAward from '@components/pages/home/OurAward';
import { Grid } from 'antd';
import { useRouter } from 'next/router';
import { getCookie, getImageAbsoluteUrl } from 'utils';
import { listLogo, listLogoMobile } from '@components/pages/home/contants';
import { siteUrl } from '@common/constant';

const HomeBanner = dynamic(() => import('@components/_v2/pages/home/Banner'));

const { useBreakpoint } = Grid;

const Home = ({ latestPosts, listBlogHaveEbook }) => {
  const { t } = useTranslation();
  const { sm } = useBreakpoint();
  const router = useRouter();
  const { pathname, asPath, query } = router;

  useEffect(() => {
    const currentLanguage = getCookie('language');

    if (currentLanguage) {
      router.push({ pathname, query }, asPath, { locale: currentLanguage });
    } else if (
      typeof window !== undefined &&
      (window?.navigator?.language === 'ja' || (navigator as any)?.userLanguage === 'ja')
    ) {
      router.push({ pathname, query }, asPath, { locale: 'ja' });
    }
  }, [pathname]);

  const [isVisibleModalVideo, setIsVisibleModalVideo] = useState(false);
  return (
    <div>
      <Head>
        <meta name="keywords" content={t('common.txtMetaKeywordApp')} />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organization',
              url: siteUrl,
              logo: getImageAbsoluteUrl('/images/company-logo.png'),
            }),
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'LocalBusiness',
              name: 'Ekotek',
              image: getImageAbsoluteUrl('/icons/logo-76.png'),
              '@id': '',
              url: siteUrl,
              telephone: '024 6658 3530',
              address: {
                '@type': 'PostalAddress',
                streetAddress: '4F, CIC Tower, No.2 Nguyen Thi Due St., Yen Hoa, Cau Giay',
                addressLocality: 'Hanoi',
                postalCode: '100000',
                addressCountry: 'VN',
              },
              geo: {
                '@type': 'GeoCoordinates',
                latitude: 21.0195315,
                longitude: 105.7911763,
              },
              sameAs: [
                'https://www.facebook.com/ekotekdotvn',
                'https://x.com/ekotekdotvn',
                'https://www.youtube.com/@ekoios',
                'https://www.linkedin.com/company/ekotek-vn/',
              ],
            }),
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'VideoObject',
              name: t('common.appTitleV2'),
              description: t('common.appDescription'),
              thumbnailUrl: [`${getImageAbsoluteUrl('/images/image-intro.png')}`],
              uploadDate: '2022-02-14T08:00:00+08:00',
              duration: 'PT1M09S',
              contentUrl: 'https://www.youtube.com/watch?v=_Gf_2AvyqTQ',
              embedUrl: 'https://www.youtube.com/watch?v=_Gf_2AvyqTQ',
              interactionStatistic: {
                '@type': 'InteractionCounter',
                interactionType: { '@type': 'WatchAction' },
                userInteractionCount: 1021,
              },
            }),
          }}
        />
        <link
          rel="preload"
          href="https://res.cloudinary.com/dwwnfqlxc/image/upload/v1691403420/web3-acceleration-banner_d93vil.webp"
          as="image"
          imageSrcSet="
          https://res.cloudinary.com/dwwnfqlxc/image/upload/v1691402380/web3-banner-mobile_u7aiql.png 500w, 
          https://res.cloudinary.com/dwwnfqlxc/image/upload/v1691403420/web3-acceleration-banner_d93vil.webp 800w"
        />
      </Head>

      <div className="home">
        <HomeBanner />

        <OrganizationsV2 />

        <OurServicesV2 />

        <OurPortfolioV2 />

        <OurAchievementsV2 />

        <OurAward listLogo={listLogo(t)} listLogoMobile={listLogoMobile(t)} iconClickable={false} />

        <OurClientsSay />

        <div className="container">
          <Insight
            className="insight-v3"
            listBlogHaveEbook={listBlogHaveEbook || []}
            iconDownload="/icons/download.svg"
            showBackground
          />
        </div>

        <TechInsights posts={latestPosts || []} />

        <div className="container">
          <ContactUsForm
            titleForm={sm ? t('v3_homepage.see_how_we_can') : t('v3_homepage.see_how_we_can_mobile')}
            subtitleForm={t('v3_homepage.leave_your_email')}
            textSendMessage={t('common.textSendMessages')}
          />
        </div>

        <ModalVideo isVisibleModalVideo={isVisibleModalVideo} setIsVisibleModalVideo={setIsVisibleModalVideo} />
      </div>
    </div>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ locale = '' }: any) => {
  // export const getStaticProps: GetStaticProps = async ({ locale = '' }: any) => {
  const posts = (await postAPI
    .getList({
      params: {
        per_page: 50,
        lang: locale,
        categories_exclude: 602,
      },
    })
    .then(function (response) {
      const latestPosts = response.data
        .filter((el, i) => i < 5)
        .map((item) => {
          return {
            title: item.title.rendered,
            link: item.slug,
            categories: item.categories_name.map((item) => item.name),
            thumbnail: item.images.medium_large,
            readingTime: item.reading_time,
            subtitle: item.excerpt.rendered,
            author: `${item.author.first_name} ${item.author.last_name}`,
            date: item.date,
          };
        });
      const listBlogHaveEbook = response.data
        .filter((e) => e.acf?.ebook)
        .map((item) => {
          return {
            title: item?.acf?.ebook_title_home_display,
            link: item.slug,
            thumbnail: item.images.medium_large,
            subtitle: item.excerpt.rendered,
            ebook: item.acf?.ebook,
            ebook_thumbnail: item.acf?.ebook_image_home_display?.sizes?.large,
          };
        });

      return { latestPosts, listBlogHaveEbook };
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })) as any;

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      latestPosts: JSON.parse(JSON.stringify(posts?.latestPosts)),
      listBlogHaveEbook: JSON.parse(JSON.stringify(posts?.listBlogHaveEbook)),
      locale,
    },
    // revalidate: 60,
  };
};

export default Home;
